$black: #040404;
$pink: #feb6a0;
$orange: #f26b41;
$orange-l: #f3a95f;
$yellow: #ffc924;
$blue-d: #29285d;
$blue-l: #c7ecff;
$green: #65b89e;
$grey: #807e7e;
$grey-m: #e8e8e8;
$grey-l: #f9f9f9;
$grey-l2: #eeeeee;
$global-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);

$big1-font: 65px;
$big2-font: 75px;
$mid1-font: 35px;
$mid2-font: 45px;
$sm1-font: 20px;
$sm2-font: 25px;
$sm3-font: 30px;
// 1659 break pt
$big1-font-b1: 40px;
$big2-font-b1: 55px;
$mid1-font-b1: 25px;
$mid2-font-b1: 35px;
$sm1-font-b1: 15px;
$sm2-font-b1: 18px;
$sm3-font-b1: 22px;

// 550 break pt
$big1-font-b7: 30px;
$big2-font-b7: 35px;
$mid1-font-b7: 20px;
$mid2-font-b7: 25px;
$sm1-font-b7: 13px;
$sm2-font-b7: 15px;
$sm3-font-b7: 18px;

//For 2k, 4k, 5k, 8k
// For 2k
$big1-font-2k: 95px;
$big2-font-2k: 105px;
$mid1-font-2k: 55px;
$mid2-font-2k: 65px;
$sm1-font-2k: 30px;
$sm2-font-2k: 35px;
$sm3-font-2k: 40px;

// For 4k
$big1-font-4k: 105px;
$big2-font-4k: 125px;
$mid1-font-4k: 70px;
$mid2-font-4k: 80px;
$sm1-font-4k: 40px;
$sm2-font-4k: 45px;
$sm3-font-4k: 50px;

// For 6k
$big1-font-6k: 125px;
$big2-font-6k: 145px;
$mid1-font-6k: 90px;
$mid2-font-6k: 110px;
$sm1-font-6k: 60px;
$sm2-font-6k: 55px;
$sm3-font-6k: 65px;

//End of 2k, 4k, 6k, 8k

// Break Points
// For @media(max-width)
$b6k: 5760px;
$b4k: 3840px;
$b2k: 2560px;
$b1: 1659px;
$b2: 1439px;
$b3: 1199px;
$b4: 1023px;
$b5: 767px;
$b6: 600px;
$b7: 550px;
$b8: 400px;
$b9: 360px;
// For @media(min-width)
$bu6k: 5761px;
$bu4k: 3841px;
$bu2k: 2561px;
$bu1: 1660px;
$bu2: 1440px;
$bu3: 1200px;
$bu4: 1024px;
$bu5: 768px;
$bu6: 601px;
$bu7: 551px;
$bu8: 401px;
$bu9: 361px;
// For @media(max-height)
$bh1: 1079px;
$bh2: 899px;
$bh3: 767px;
$bh4: 599px;
$bh5: 499px;
