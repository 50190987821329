h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: Graphik-Bold;
}

p {
    margin: 0;
}

h1 {
    font-size: $big2-font;
}

h1.MuiTypography-root,
h2.MuiTypography-root,
h3.MuiTypography-root,
h4.MuiTypography-root,
h5.MuiTypography-root,
h6.MuiTypography-root {
    margin: 0;
    font-family: Graphik-Bold;
}
h1.MuiTypography-root {
    font-size: $big2-font;
}
p.MuiTypography-root {
    margin: 0;
    font-family: Poppins-Medium;
} 

section {
    padding: 5vw 0;
}

.btn,
.form-control {
    box-shadow: unset !important;
    padding: 1rem;
    font-size: $sm1-font;
    border-width: 2px;
}

.form-control {
    height: 100%;
}

.form-control.transparent {
    background-color: transparent;
    border-color: white;
    &:hover {
        background-color: white;
    }

    &:not(:hover)::placeholder {
        color: white;
    }
}

.form-control.b-border-o {
    border-width: 0 0 2px 0;
    border-radius: 0;
}

.btn {
    border-radius: 10px;
    &:not(.bars):not(.submenu):not(.menu-holder) {
        min-width: 120px;
    }
    .arr {
        width: 10px;
        filter: invert(100%);
        transform: rotate(-90deg);
    }

    &.btn-lg {
        padding: 1.25rem;
    }

    &.btn-sm {
        padding: 0.75rem;
        border-radius: 6px;
    }
}

.btn-primary {
    background-color: $black !important;
    border-color: $black !important;
    &:hover,
    &:active {
        background-color: rgba($color: $black, $alpha: 0.8) !important;
    }
}

.btn-outline-primary {
    border-color: $black !important;
    color: $black;
    &:hover,
    &:active {
        background-color: $black !important;
    }
}

form {
    .btn {
        border-radius: 5px;
    }
}

.MuiDialog-root {
    z-index: 9999 !important;
}

.MuiDialogContent-root {
    padding: 30px 20px !important;
}

.MuiDialog-paperWidthMd,
.MuiDialog-paperWidthLg,
.MuiDialog-paperWidthSm {
    width: 100%;
}

.MuiDialog-paperWidthLg {
    min-height: 70vh;
}

@media (max-width: $b1) {
    h1 {
        font-size: $big2-font-b1;
    }
    h1.MuiTypography-root {
        font-size: $big2-font-b1;
    }

    .btn,
    .form-control {
        font-size: $sm1-font-b1;
    }
}

@media (max-width: $b4) {
    section {
        padding: 60px 15px;
    }
}

@media (max-width: $b7) {
    h1 {
        font-size: $big2-font-b7;
    }
    h1.MuiTypography-root {
        font-size: $big2-font-b7;
    }

    .btn,
    .form-control {
        font-size: $sm1-font-b7;
    }
}

@media (min-width: $bu2k) {
    .btn,
    .form-control {
        font-size: $sm1-font-2k;
        padding: 2rem;
    }

    .btn {
        &:not(.bars):not(.submenu):not(.menu-holder) {
            min-width: 180px;
        }
        &.btn-lg {
            padding: 2.5rem;
        }

        &.btn-sm {
            padding: 1.5rem;
        }
    }
}

@media (min-width: $bu4k) {
    .btn,
    .form-control {
        font-size: $sm1-font-4k;
        padding: 4rem;
    }

    .btn {
        &:not(.bars):not(.submenu):not(.menu-holder) {
            min-width: 240px;
        }
        &.btn-lg {
            padding: 3.25rem;
        }

        &.btn-sm {
            padding: 2.25rem;
        }
    }
}

@media (min-width: $bu6k) {
    .btn,
    .form-control {
        font-size: $sm1-font-6k;
    }

    .btn {
        &:not(.bars):not(.submenu):not(.menu-holder) {
            min-width: 300px;
        }
        &.btn-lg {
            padding: 4.25rem;
        }

        &.btn-sm {
            padding: 3.25rem;
        }
    }
}

@media (hover: hover) {
    *::-webkit-scrollbar {
        width: 0.25rem;
        height: 0.25rem;
    }

    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba($color: $black, $alpha: 0.3);
    }

    *::-webkit-scrollbar-thumb {
        background-color: $black;
        outline: 1px solid $grey-l;
    }

    * {
        scrollbar-color: #fff #000;
        scrollbar-width: thin;
    }
}

// .formatter {
// }
