.big1-font {
    font-size: $big1-font !important;
}
.big2-font {
    font-size: $big2-font !important;
}
.mid1-font {
    font-size: $mid1-font !important;
}
.mid2-font {
    font-size: $mid2-font !important;
}
.sm1-font {
    font-size: $sm1-font !important;
}
.sm2-font {
    font-size: $sm2-font !important;
}
.sm3-font {
    font-size: $sm3-font !important;
}

@media (max-width: $b1) {
    .big1-font {
        font-size: $big1-font-b1 !important;
    }
    .big2-font {
        font-size: $big2-font-b1 !important;
    }
    .mid1-font {
        font-size: $mid1-font-b1 !important;
    }
    .mid2-font {
        font-size: $mid2-font-b1 !important;
    }
    .sm1-font {
        font-size: $sm1-font-b1 !important;
    }
    .sm2-font {
        font-size: $sm2-font-b1 !important;
    }
    .sm3-font {
        font-size: $sm3-font-b1 !important;
    }
}

@media (max-width: $b7) {
    .big1-font {
        font-size: $big1-font-b7 !important;
    }
    .big2-font {
        font-size: $big2-font-b7 !important;
    }
    .mid1-font {
        font-size: $mid1-font-b7 !important;
    }
    .mid2-font {
        font-size: $mid2-font-b7 !important;
    }
    .sm1-font {
        font-size: $sm1-font-b7 !important;
    }
    .sm2-font {
        font-size: $sm2-font-b7 !important;
    }
    .sm3-font {
        font-size: $sm3-font-b7 !important;
    }
}

// for 2k, 4k, 5k, 8k

@media (min-width: $bu2k) {
    .big1-font {
        font-size: $big1-font-2k !important;
    }
    .big2-font {
        font-size: $big2-font-2k !important;
    }
    .mid1-font {
        font-size: $mid1-font-2k !important;
    }
    .mid2-font {
        font-size: $mid2-font-2k !important;
    }
    .sm1-font {
        font-size: $sm1-font-2k !important;
    }
    .sm2-font {
        font-size: $sm2-font-2k !important;
    }
    .sm3-font {
        font-size: $sm3-font-2k !important;
    }
}

@media (min-width: $bu4k) {
    .big1-font {
        font-size: $big1-font-4k !important;
    }
    .big2-font {
        font-size: $big2-font-4k !important;
    }
    .mid1-font {
        font-size: $mid1-font-4k !important;
    }
    .mid2-font {
        font-size: $mid2-font-4k !important;
    }
    .sm1-font {
        font-size: $sm1-font-4k !important;
    }
    .sm2-font {
        font-size: $sm2-font-4k !important;
    }
    .sm3-font {
        font-size: $sm3-font-4k !important;
    }
}

@media (min-width: $bu6k) {
    .big1-font {
        font-size: $big1-font-6k;
    }
    .big2-font {
        font-size: $big2-font-6k;
    }
    .mid1-font {
        font-size: $mid1-font-6k;
    }
    .mid2-font {
        font-size: $mid2-font-6k;
    }
    .sm1-font {
        font-size: $sm1-font-6k;
    }
    .sm2-font {
        font-size: $sm2-font-6k;
    }
    .sm3-font {
        font-size: $sm3-font-6k;
    }
}

