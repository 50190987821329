@font-face {
    font-family: Graphik-Bold;
    src: url(../fonts/Graphik/GraphikBold.otf);
}

@font-face {
    font-family: Poppins-Medium;
    src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

/* @font-face {
    font-family: Angelia-Davitson;
    src: url(..fonts/hand-written-fonts/Angelia-Davitson.otf);
}
@font-face {
    font-family: Author-Think;
    src: url(..fonts/hand-written-fonts/Author-Think.otf);
}
@font-face {
    font-family: Autography;
    src: url(..fonts/hand-written-fonts/Autography.otf);
}
@font-face {
    font-family: BrittanySignature;
    src: url(..fonts/hand-written-fonts/BrittanySignature.ttf);
}
@font-face {
    font-family: handfont5;
    src: url(..fonts/hand-written-fonts/Handestonie.otf);
}
@font-face {
    font-family: High-Spirited;
    src: url(..fonts/hand-written-fonts/High-Spirited.ttf);
}
@font-face {
    font-family: Mastrih;
    src: url(..fonts/hand-written-fonts/Mastrih.otf);
}
@font-face {
    font-family: Photograph-Signature;
    src: url(..fonts/hand-written-fonts/Photograph-Signature.ttf);
}
@font-face {
    font-family: RetroSignature;
    src: url(..fonts/hand-written-fonts/RetroSignature.otf);
}
@font-face {
    font-family: The-Boston;
    src: url(..fonts/hand-written-fonts/The-Boston.otf);
} */