// colors

.black {
    color: $black;
}
.pink {
    color: $pink;
}
.orange {
    color: $orange;
}
.orange-l {
    color: $orange-l;
}
.yellow {
    color: $yellow;
}
.blue-d {
    color: $blue-d;
}
.blue-l {
    color: $blue-l;
}
.green {
    color: $green;
}
.grey {
    color: $grey;
}
.grey-m {
    color: $grey-m;
}
.grey-l2 {
    color: $grey-l2;
}

// Background-colors
.black-bg {
    background-color: $black;
}
.pink-bg {
    background-color: $pink;
}
.orange-bg {
    background-color: $orange;
}
.orange-l-bg {
    background-color: $orange-l;
}
.yellow-bg {
    background-color: $yellow;
}
.blue-d-bg {
    background-color: $blue-d;
}
.blue-l-bg {
    background-color: $blue-l;
}
.green-bg {
    background-color: $green;
}
.grey-bg {
    background-color: $grey;
}

.grey-m-bg {
    background-color: $grey-m;
}
.grey-l2-bg {
    background-color: $grey-l2;
}
