@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "./assets/scss/variable.scss";
@import "./assets/scss/fonts.scss";
@import "./assets/scss/font-sizes.scss";
@import "./assets/scss/resetinitial.scss";
@import "./assets/scss/colors.scss";
body {
    font-family: Poppins-Medium;
}

.header {
    height: 120px;
}

.container {
    max-width: 100%;
    padding: 0 6vw;
}

.main-content-area {
    min-height: calc(100vh - 150px);
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $orange !important;
}
.add-modal {
    .MuiDialog-paperWidthSm{
        max-width: 750px;
    }
}
.dragabble-obj {
    border: 1px dashed;
    background-color: white;
    cursor: pointer;
}

@media (min-width: $bu1) {
    body {
        font-size: $sm1-font;
    }
}

@media (min-width: $bu2k) {
    body {
        font-size: $sm1-font-2k;
    }
}

@media (min-width: $bu4k) {
    body {
        font-size: $sm1-font-4k;
    }
}

@media (min-width: $bu6k) {
    body {
        font-size: $sm1-font-6k;
    }
}

.bg-black {
    background-color: #040404!important;
    border-color: #040404!important;
}

.swal2-container {
    z-index: 10000 !important;    
    .swal2-title {
        font-family: Poppins-Medium;
        font-weight: 400;
    }
}
.document-preview {
    .react-pdf__Page__canvas {
        margin: 0 auto;
    }
}
// Notes
// 1. Need to work on grow and shrink detection based on positions.
// 2. Need to work on negetive top & negetive left delete child.
// 3. Worked on Valition only in company component.
// 4. Need to implement valition based modification on all component.
