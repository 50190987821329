.single-resizer {
  z-index: 100;
}


.react-draggable {
  z-index: 3;
  position: fixed !important;
}

.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #040404 !important;
}

.MuiFormLabel-colorSecondary.Mui-focused {
  color: #040404 !important;
}

.avatarMenu .MuiPopover-paper {
  width: 100%;
  max-width: 144px;
  left: unset !important;
  right: 2%;
  top: 64px !important;
}

.avatarMenu .MuiPopover-paper .MuiMenu-list .MuiListItem-root {
  justify-content: center;
}

a {
  text-decoration: none;
  color: #f26b41;
}

a:hover {
  color: #d26c41;
  text-decoration: none;
}

.login-secion .password-container .MuiFormHelperText-root {
  position: absolute;
  top: 100%;
  left: 0;
}

.MuiTooltip-tooltip {
  font-size: 15px !important;
  line-height: 18px !important;
}

.login-secion .password-container {
  margin-bottom: 15px;
}

.login-secion .typography {
  font-size: 16px !important;
  font-family: Poppins-Medium;
  letter-spacing: 0;
}

.forgot-password-view .typography {
  font-size: 16px !important;
  font-family: Poppins-Medium;
  letter-spacing: 0;
}

.MuiTypography-root.MuiTypography-paragraph {
  font-family: Poppins-Medium;
}

.login-secion input:-webkit-autofill,
.login-secion input:-webkit-autofill:hover,
.login-secion input:-webkit-autofill:focus,
.login-secion input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f3f4f8 inset !important;
}

.document-list-tool-tip {
  font-size: 13px !important;
}

/* Global Buttons Starts Here */
.MuiButton-root.MuiButton-contained {
  font-family: Poppins-Medium;
  border-radius: 10px;
}

.MuiButton-root.MuiButton-contained:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.MuiButton-root.btn-lg {
  padding: 15px;
  min-width: 120px;
}

.MuiButton-root.btn-sm {
  min-width: 75px;
  padding: 5px;
  border-radius: 6px;
}

.MuiButton-root.MuiButton-contained.black {
  background-color: #202020;
  color: #fff;
}

.MuiButton-root.MuiButton-outlined {
  font-family: Poppins-Medium;
  padding: 15px;
  border-radius: 10px;
}

.MuiButton-root.MuiButton-outlined.black {
  color: #202020;
  border: 3px solid #202020;
}

.MuiButton-root.MuiButton-contained.orange {
  background-color: #f26b41;
  color: #fff;
}

/* Global Buttons Ends Here */

/* Fonts starts */

/* Fonts ends */


.MuiAutocomplete-popper {
  z-index: 999999999999999 !important;
}

.file-name-overflow {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}